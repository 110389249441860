import classes from './App.module.css';
import Social from "./Social";
import ig from "./image/logo/ig.svg"
import linkedin from "./image/logo/linkedin.svg"
import profi from "./image/logo/profi.svg"
import rutube from "./image/logo/rutube.svg"
import telegram from "./image/logo/telegram.svg"
import tiktok from "./image/logo/tiktok.svg"
import vk from "./image/logo/vk.svg"
import ya from "./image/logo/ya.svg"
import youtube from "./image/logo/youtube.svg"
import fb from "./image/logo/fb.svg"
import twitch from "./image/logo/twitch.svg"
import zen from "./image/logo/zen.svg"

function App() {
  return (
    <div className={classes.app}>
      <div className={classes.main}>
        <Social logo={youtube} link={"https://youtube.com/@nikitavasin"} text={"YouTube"}/>
        <Social logo={youtube} link={"https://youtube.com/@nikitavasinshorts"} text={"YouTube Shorts"}/>
        <Social logo={tiktok} link={"https://tiktok.com/@nikitavasinofficial"} text={"TikTok"}/>
        <Social logo={vk} link={"https://vk.com/nikitavasin"} text={"VK"}/>
        {/*<Social logo={telegram} link={"https://t.me/nikitavasin"} text={"Telegram"}/>*/}
        <Social logo={telegram} link={"https://t.me/nikitavasinchannel"} text={"Telegram Channel"}/>
        <Social logo={linkedin} link={"https://linkedin.com/in/nikitavasin"} text={"LinkedIn"}/>
        <Social logo={profi} link={"https://profi.ru/profile/VasinNA4/"} text={"Profi"}/>
        <Social logo={rutube} link={"https://rutube.ru/channel/24727590/"} text={"RuTube"}/>
        <Social logo={zen} link={"https://dzen.ru/nikitavasin"} text={"Zen"}/>
        <Social logo={ig} link={"https://instagram.com/nikitavasinofficial"} text={"Instagram"}/>
        <Social logo={twitch} link={"https://twitch.tv/nikitavasin"} text={"Twitch"}/>
        <Social logo={ya} link={"mailto:mail@nikitavasin.ru"} text={"Email"}/>
        <Social logo={fb} link={"https://facebook.com/nikitavasinofficial"} text={"Facebook"}/>
      </div>
    </div>
  );
}

export default App;
