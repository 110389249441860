// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".social_holder__9OqZq {\n    background-color: white;\n    padding: 10px 20px;\n    border-radius: 15px;\n    gap: 15px;\n    display: flex;\n    height: 50px;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: flex-start;\n    align-items: center;\n}\n\na {\n    color: black;\n    text-underline: none;\n    text-decoration: none;\n}\n\n.social_holder__9OqZq .social_social_logo__yxm-k {\n    width: 25px;\n    height: 25px;\n    filter: grayscale(4) contrast(1);\n    display: block;\n}\n\n.social_holder__9OqZq .social_text__L0aWW {\n    display: block;\n}", "",{"version":3,"sources":["webpack://./src/social.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,qBAAqB;IACrB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".holder {\n    background-color: white;\n    padding: 10px 20px;\n    border-radius: 15px;\n    gap: 15px;\n    display: flex;\n    height: 50px;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: flex-start;\n    align-items: center;\n}\n\na {\n    color: black;\n    text-underline: none;\n    text-decoration: none;\n}\n\n.holder .social_logo {\n    width: 25px;\n    height: 25px;\n    filter: grayscale(4) contrast(1);\n    display: block;\n}\n\n.holder .text {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holder": "social_holder__9OqZq",
	"social_logo": "social_social_logo__yxm-k",
	"text": "social_text__L0aWW"
};
export default ___CSS_LOADER_EXPORT___;
