import React from 'react';
import classes from './social.module.css'

const Social = ({logo, text, link}) => {
    return (
        <a href={link} target={'_blank'} className={classes.holder}>
            <img className={classes.social_logo} src={logo} alt={text}/>
            <div className={classes.text}>{text}</div>
        </a>
    );
};

export default Social;